@import '../../animation.scss';

.section-2 {
	background: #EF8440;
	display: flex;
	flex-direction: column;
	gap: 40px;
	align-items: flex-start;
	overflow: hidden;

	.content  {
		padding-right: 80px;
	}
}

.image {
	width: 676px;
	height: 555px;
}

.learn-more {
	:hover {
		cursor: pointer;
	}
	margin-top: 30px;
	text-decoration: underline solid white 1px;
}



@media screen and (min-width: 769px) {
	.section-2 {
		margin: auto;
	}	
}

@media screen and (min-width: 1350px) {
	.section-2 {
		flex-direction: row;
		align-items: center;
		
	}

	.section-2 .content {
		max-width: 50%;
	}

}

@media screen and (min-width: 1480px) {
	.section-2 {	
		margin: auto;
	}

	.section-2 .image {
		max-width: 50%;
	}

	.section-2 .content {
		max-width: 50%;
	}
}

.section-2 .image .retangle-1 {
	animation: slideToBottom 1.6s;
}

.section-2 .image .retangle-2 {
	animation: slideToTop 1.6s;
}

.section-2 .image .content {
	animation: slideToRight 1.6s;
}

.section-2 .content .title,
.section-2 .content .desc,
.section-2 .content .learn-more
{
	animation: slideToLeft 1.6s;
}

.section-2 .image {
	position: relative;
}

.section-2 .image .retangle-1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 50%;
	background-color: #2b3440;
	z-index: 0;
}

.section-2 .image .retangle-2 {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 50%;
	height: 50%;
	background-color: #2b3440;
	z-index: 0;
}

.section-2 .image .content {
	padding: 20px;
	position: relative;
	z-index: 1;
}

.section-2 .image .content img {
	border-radius: 5px;
	object-fit: cover;
}

@media screen and (max-width: 662px) {
	.section-2 {
		margin-top: 30px;
		width: 100%;
	}
	.section-2 .image .content img {
		width: 100%;
	}
	.content {
		padding: 20px;
	}
}

.section-2 .content .title {
	font-size: 48px;
	color: white;
}

.section-2 .content .desc {
	font-size: 16px;
	color: white;
	margin-top: 30px;
}

.section-2 .content .socmed {
	margin-top: 20px;
}
