.footer-container {
	background: var(--dark-security);
}

.footer-content {
	display: flex;
	flex-direction: column;
	padding: 20px 0px;
	gap: 20px;
}

.socmed {
	margin: 30px 0 30px 0;
}

// p {
// 	color: #FBF6F3;
// 	font-style: normal;
// 	font-weight: 300;
// 	font-size: 14px;
// 	line-height: 25px;
// 	// font-family: 'AcuminPro';
// 	letter-spacing: 0.01em;
// }

@media screen and (min-width: 992px) {
	.footer-content {
		display: flex;
		flex-direction: row;
		// align-items: center;
		justify-content: space-between;
		gap: 0;
		padding: 30px 0px;
		max-width: 1500px;
		// margin: auto;
		background: var(--dark-security);
	}
}

.footer-container .copyright {
	font-size: 16px;
}

.footer-container .socmed {
	.web {
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.mobile {
		display: none;
	}

	.image {
		width: 40px;
		height: 40px;
	}

	@media screen and (max-width: 992px) {
		.web {
			display: none;
		}

		.mobile {
			display: flex;
			align-items: center;
			gap: 20px;
		}
	}
}

@media screen and (max-width: 662px) {
	.footer-content {
		width: 100%;
		background-color: #1C1E19;
	}

	.list-footer {
		width: 100%;
		justify-content: space-between;
		padding: 0 20px;
	}

	.footer-logo {
		display: none;
		visibility: hidden;
	}
}

.copyright {
	font-weight: bold;
}