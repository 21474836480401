@import '../../animation.scss';

.explore-stories {
    margin: auto;
    animation: slideToTop 1.6s;
    padding-bottom: 80px;
    .title {
        margin-top: 80px;
    }
}

.stories-container {
    padding: 30px 0px 0 0px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
    justify-items: center;

    video {
        border-radius: 5px;
        margin: auto;
    }

    img {
        width: 284px;
        height: 245px;
        border-radius: 5px;
        object-fit: cover;
    }
}

.reels {
    width: 284px;
}

@media screen and (max-width: 500px) {
    .stories-container {
        width: 100%;
        padding: 20px;
        overflow: scroll;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        ::-webkit-scrollbar {
            display: none;
        }
    }
    .explore-stories {
        width: 100%;
    }

}