@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* --color-bg: #141E30; */
  --color-bg: #FFFBF9;
  --color-text-white: #fff;
  --color-text-blue: #003051;
  --color-button-hover: #005690;
  --color-button-disabled: #707d86;
  --color-input-focus: #003051;
  --white: #fff;
  --blue: #003051;
  --blue200: #141E30;
  --yellow: #FFFBD1;
  --yellow200: #b1aa5d;
  --grey100: #D4D4D4;
  --grey200: #9D9D9D;
  --grey300: #333741;
  --black100: #000;
  --black200: #212121;
  --dark-security: #1C1E19;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  color: var(--white);
  margin-bottom: 10px;
  margin-top: 20px;
}

li {
  margin-bottom: 15px;
  font-family: Nunito;
  font-size: 16px;
}

a {
  color: var(--color-a);
  white-space: nowrap;
}

.no-scroll {
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]){
  border-top-width: 0px;
}

.btn-balance{
  border-radius: 0px !important;
}

.btn-balance-left{
  border-right: solid 1px rgb(255 255 255 / 0.1) !important;   
  border-bottom-left-radius: 5px !important;
}

.btn-balance-right{
  border-bottom-right-radius: 5px !important;
}

.filter-white{
  filter: invert(1) sepia(1) saturate(5) hue-rotate(175deg);
}

.filter-black{
  filter: brightness(0%);
}

@media screen and (max-width: 475px) {
  .slider-latest-nft > .slick-prev{
    display: none !important;
  }
  
  .slider-latest-nft > .slick-next{
    display: none !important;
  }

  .slider-latest-nft > .slick-list {
    padding: 0 !important;
  }

  .slider-latest-nft > .slick-list > .slick-track{
    width: 5000px !important;
  }

  .slider-latest-nft > .slick-list > .slick-track > div {
    margin-right: 20px;
  }
}

@media screen and (min-width: 475px){
  .slick-slider .slick-slide {
    padding: 0 15px;
  }
}


.slick-prev {
  transform: translate(20px, -50%) !important;
  z-index: 9;
}

.slick-next {
  transform: translate(-20px, -50%) !important;
}



.slider-latest-nft > .slick-dots {
  transform: translateY(20px);
}



.slider-latest-nft > .slick-dots > .slick-active {
  background-color: lemonchiffon;
  opacity: 1;
}

.slider-latest-nft > .slick-dots > li {
  height: 10px;
  width: 10px;
  background-color: var(--white);
  opacity: 0.4;
  border-radius: 100%;
}

.scrollbar-x-hide::-webkit-scrollbar { width: 0 !important }
.scrollbar-x-hide { overflow: -moz-scrollbars-none; }

.chart-corner > canvas{
  width: 65px !important;
  height: 20px !important;
}

.input-amount{
  outline: none !important;
  border: none !important;
}

/* .eye-icon > path {
  fill: rgba(255, 255, 255, 0.5)
} */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: 5000s ease-in-out 0s;
}

/* remove arrows from input type number with CSS. */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.arrow-latest-slick{
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
  backdrop-filter: blur(100px) !important;
  height: 45px !important;
  width: 45px !important;
  padding: 11px !important;
  border-radius: 100%;
}

.slick-disabled{
  background: rgba(255, 255, 255, 0.4) !important;
}

.Toastify > .Toastify__toast-container{
  z-index: 9999999;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: lemonchiffon;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: lemonchiffon;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
