@import '../../animation.scss';

.jumbotron {
	height: 100vh;
	background-size: cover;
	background-color: #FBF6F3;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.hero {
	position: relative;
	display: flex;
	top: 10vh;
	height: fit-content;
	padding-bottom: 60px;
	margin: auto;
	justify-content: center;
	z-index: 1;
}

.hero-image {
	// width: 70%;
	margin-left: 30px;
}

.flower {
	justify-content: center;
	margin-top: 60px;
	#first {
		margin-left: 30px;
		position: relative;
		width: 272px;
		height: 210px;
	}
	#second {
		display: block;
		margin-left: auto;
		margin-top: -22px;
		margin-right: 7px;
		position: relative;
		width: 303px;
		height: 81px;
	}
}

.herotitle {
	font-family: 'Expressway';
	font-style: normal;
	font-weight: 700;
	font-size: 72px;
	line-height: 85px;
	letter-spacing: 0.01em;
	color: #1C1E19;
	margin-right: 30px;
	span {
		color: #EF8440;
		margin-right: 15px;
	}
}

.lp-image {
	float: right;
	width: 704px;
	height: 556px;
	animation: slideToLeft 1.6s;
}

.hero-right-desc {
	animation: slideToLeft 1.6s;
}

.hero #hero-title {
	animation: slideToRight 1.6s;
	margin-top: 30px;
}

.hero .socmed-container {
	animation: slideToRight 1.8s;
}

.hero .web-text {
	display: none;
}

.hero .title {
	color: #FFFBD1;
	font-size: 56px;
	margin: 0;
}

.hero .desc {
	width: 540px;
	color: #FAFAFA;
	font-size: 20px;
	margin-top: 10px;
}

.hero .socmed-container {
	margin-top: 30px;
}

.scroll-text {
	letter-spacing: 4px;
}

.hero-desc {
	max-width: 500px;
}


@keyframes infiniteUpDown {
	0% {
		transform: translateY(0);
	}
	20% {
		transform: translateY(-1px);
	}
	100% {
		transform: translateY(-10px);
	}
}

@keyframes infiniteRotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

@media screen and (max-width: 600px) {
	.hero {
		flex-direction: column;
		top: 6vh;
		width: 100%;
		padding: 0 20px;
	}
	.flower {
		margin-top: 20px;
	}
	.hero-image {
		margin-top: -120px;
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		flex-direction: column;
	}
	.hero-right-desc {
		display: none;
	}
	.description {
		width: 100%;
		padding: 0 0px;
		margin-top: -120px;
		text-align: justify;
	}
	.herotitle {
		font-size: 40px;
		line-height: 50px;
	}
	.lp-image {
		margin-top: 20px;
	}
}

@media screen and (min-width: 992px) {
	.hero {
		display: flex;
		top: 4vh;
		padding-bottom: 90px;
		z-index: 1;
	}

	.hero .web-text {
		display: block;
	}

	.hero .mobile-text {
		display: none;
	}

}

@media screen and (max-height: 850px) {
	.jumbotron {
		height: 110vh;
	}
}
@media screen and (max-height: 760px) {
	.jumbotron {
		height: 115vh;
	}
}
@media screen and (min-height: 1350px) {
	.jumbotron {
		height: 60vh;
	}
}
@media screen and (min-height: 1700px) {
	.jumbotron {
		height: 50vh;
	}
}