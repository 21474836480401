@import '../../animation.scss';

.section-3 {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 80px;
	padding-top: 80px;
	background: white;
	width: 1440px;
	margin: auto;
}

.blank-page {
	height: 100vh;
	background: white;
}

.section-3 .flex {
	margin-top: 30px;
	margin: auto;
	display: none;
	margin-bottom: 60px;
}

.section-3-content-wrapper {
	margin: auto;
}

.hiw-content {
	height: 300px;
	margin: auto;
	justify-content: center;
}

.section-3 .flex .content-1 {
	width: 100%;
	height: 100%;
	text-align: center;
	margin: 0;
}

.section-3 .flex .step {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin: 0;
	z-index: 10;
}

.section-3 .flex .content .text {
	padding: 20px;
}

.section-3 .col .content .text {
	padding: 0 20px;
	width: 100vw;
}

.section-3 .title {
	animation: slideToBottom 1.2s;
	margin-bottom: 30px;
}

.section-3 .col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	text-align: center;
	margin: 0;
	animation: slideToTop 1.2s;
}

.section-3 .col .content {
	margin-top: 10px;
	white-space: normal;
}

.section-3 .col .content .flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

hr.dash {
	border: 0;
	border-top: 1px dashed #FFFBD1;
	width: 100%;
}

.hiw-desc {
	max-width: 400px;
	margin: auto;
}

.logo {
	margin: auto;
	margin-bottom: 30px;
}

@media screen and (max-width: 500px) {
	.section-3 {
		padding-top: 30px;
		width: 100%;
		height: 110vh;
		flex-direction: column;
		display: flex;
	}
	.section-3-content-wrapper {
		width: 100%;
	}
	.hiw-content {
		display: flex;
		flex-direction: column;
	}
	.content1 {
		margin-bottom: 30px;
		padding: 0 20px;
	}
}

@media screen and (max-width: 1050px) {
	.hiw-content {
		height: 260px;
	}
}

@media screen and (min-width: 1000px) {
  .section-3 .flex {
		display: flex;
	}

  .section-3 .col {
		// display: none;
	}

	.section-3 {
		flex-direction: row;
		padding: 80px 60px 30px 60px;
	}

	.section-3 .title {
		animation: slideToBottom 1.2s;
	}

	.section-3 .section-3-content-wrapper .flex .step-1 {
		animation: slideToBottom .8s;
	}

	.section-3 .section-3-content-wrapper .flex .step-2 {
		animation: slideToBottom 1s;
	}

	.section-3 .section-3-content-wrapper .flex .step-3 {
		animation: slideToBottom 1.2s;
	}

	@keyframes widthAnimation {
		from {
			width: 0px;
		}
		to {
			width: 100%;
		}
	}

	.section-3 .section-3-content-wrapper .flex .dash {
		animation: widthAnimation 1s;
	}

	.section-3 .section-3-content-wrapper .hiw-content .content-step-1 {
		animation: slideToTop 1s;
	}

	.section-3 .section-3-content-wrapper .hiw-content .content-step-2 {
		animation: slideToTop 1.4s;
	}

	.section-3 .section-3-content-wrapper .hiw-content .content-step-3 {
		animation: slideToTop 1.8s;
	}
}

@media screen and (min-width: 1480px) {
	.section-3 {
		flex-direction: row;
	}

	.section-3-content-wrapper {
		max-width: 1500px;
		// margin: auto;
	}
}
