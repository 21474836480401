
@font-face{
  font-family: "ExpressWay";
  src: local("ExpressWay"),url(../src/assets/fonts/ExpressWay.ttf) format("truetype");
}
@font-face{
  font-family: "AcuminPro";
  src: local("AcuminPro"),url(../src/assets/fonts/AcuminPro.ttf) format("truetype");
}
.card_bg{
  border:.5px solid lightgray;
  border-radius: .2em;
  padding: .8em;
  background-color: white;
}
.contact-btn{
  border: 1px solid #EF8440;
  height:60px;
  border-radius: 5px;
  color: #EF8440;
  font-weight: 500;
}
.outline_button-rounded{
  border: 1px solid black;
  background-color: transparent;
  padding:.7em 2em;
  border-radius: 1.5em;
  color: black;
  font-weight: 500;
}
.filled_button-rounded{
  border: 1px solid black;
  padding:.7em 2em;
  border-radius: 1.5em;
  color: white;
  font-weight: 500;
  background-color: black;
}