.socmed-container,
.socmed-bg-content {
	display: flex;
	align-items: center;
	gap: 20px;
	position: relative;
	
 	.dark-content {
		min-width: 40px;
		min-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		border-width: 1px;
		border-style: solid;
		border-color: #141E30;
		transition: .5s;
		cursor: pointer;
	
		&:hover {
			transform: translateY(-10px);
		}
	}
}

.socmed-container .content {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	border-width: 1px;
	border-style: ridge;
	border-color: white;
	transition: .5s;
	cursor: pointer;

	&:hover {
		background: #141E30;
		transform: translateY(-10px);
	}
}

.telegram {
	width: 25px;
	height: 25px;
}

.socmed-bg-content .content {
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	transition: .5s;
	background: #141E30;

	&:hover {
		transform: translateY(-10px);
	}
}

.socmed-container .content img {
	min-width: 20px;
	min-height: 20px;
}
