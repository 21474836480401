.section-4-faq {
	padding: 80px 0px 80px 0px;
	margin: auto;
	display: flex;  
	flex-direction: column;
	justify-content: center;
	overflow: hidden;

	.faq-container {
		// display: flex;
		// margin: auto;
	}

	.col-left {
		.nav {
			display: flex;
			flex-direction: row;
			gap: 20px;
			margin-top: 30px;

			.list {
				padding-top: 20px;
				padding-bottom: 20px;
				padding-left: 40px;
				border-radius: 5px;
				width: 358px;
				cursor: pointer;
				transition: 1s;
				background: rgba(255, 255, 255, 0.02);				;
			}

			.list-active {
				padding-top: 20px;
				padding-bottom: 20px;
				padding-left: 40px;
				border-radius: 5px;
				width: 358px;
				background: rgba(239, 132, 64, 0.1);
				transition: 1s;
			}
		}
		.title {
			animation: slideToBottom 1s;
		}
		.list-active {
			animation: slideToBottom 1s;
		}
		.list-0 {
			animation: slideToBottom 1s;
		}
		.list-1 {
			animation: slideToBottom 1.4s;
		}
		.list-2 {
			animation: slideToBottom 1.8s;
		}
		.list-3 {
			animation: slideToBottom 2.2s;
		}
		.list-4 {
			animation: slideToBottom 2.6s;
		}
	}

	.contact-btn{
		border: 1px solid #EF8440;
		height:60px;
		width: 200px;
		border-radius: 5px;
		color: #EF8440;
		font-weight: 500;
	}
	.contact-btn:hover{
		background-color:#EF8440;
		color: white;
	}
	.col-right {
		margin-top: 20px;

		.list {
			padding: 20px 0;
			// border: 1px solid #D4D4D4;
			cursor: pointer;

			.flex {
				display: flex;
				justify-content: space-between;
				gap: 20px;
				// padding: 0 20px 0 20px;
				align-items: center;

				.arrow-up {
					transform: rotate(-180deg);
					transition: 500ms
				}
				
				.arrow-down {
					transform: rotate(0deg);
					transition: 500ms
				}
			}

			.dropdown-content {
				position: relative;
				height: 0px;
				// padding: 0 20px 0 20px;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				transition: all 700ms ease;
			}
		}

		@for $i from 0 through 10 {
			.list-#{$i} {
				animation: slideToTop $i - .5 + s;
				animation-iteration-count: 1;
			}
		}
	}
}


@media screen and (max-width: 600px) {
	.faq-container {
		flex-direction: column;
		width: fit-content;
	}
	.section-4-faq {
		padding: 0px 20px;
	}

	.section-4-faq .col-left .nav {
		display: flex;
	}
	.list-active {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media screen and (min-width: 1000px) {
	.col-right {
		// margin-left: 40px;
	}
}

@media screen and (max-width: 1479px) {
	.section-4-faq .col-left .nav .list {
		padding-left: 20px;
		padding-right: 20px;
	}

	.section-4-faq .col-left .nav .list-active {
		padding-left: 20px;
		padding-right: 20px;
	}
	
}

@media screen and (min-width: 1480px) {
	.section-4-faq {
		padding: 80px 80px;

		.col-left .nav .list {
			padding-left: 20px;
			padding-right: 20px;
		}

		.col-left .nav .list-active {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
