.countdown-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0.5rem
}

.countdown-container > form {
  padding: 0.5rem;
}

.countdown-container > form > label {
  margin-right: 1rem;
}

.expired-notice {
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
  height: 2.5rem;
  background-color: #ececec;
}

.expired-notice > span {
  margin: 1rem;
  font-weight: bold;
  color: red;
  /* width: 120px; */
}

.expired-notice > p {
  font-size: 1rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  /* max-width:'100px'; */
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.75rem;
  padding: 0.9em;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
  background-color: #f5f5f5;
}

.show-counter .countdown {
  line-height: 1rem;
  /* padding: 0 0.75rem 0 0.75rem; */
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 2rem;
}

@media only screen and (max-width: 500px) {
  .show-counter .countdown > span {
    font-size: 1rem;
    line-height: 1rem;
  }

  .show-counter .countdown-link { 
    font-size: 1rem;
  }
}