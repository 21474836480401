.section-4 {
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 80px;
	padding-top: 80px;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color:black;
	// background: #141E30;

	.col-left {
		.nav {
			display: flex;
			flex-direction: row;
			gap: 20px;
			margin-top: 30px;

			.list {
				padding-top: 20px;
				padding-bottom: 20px;
				padding-left: 40px;
				border-radius: 5px;
				cursor: pointer;
				transition: 1s;
				background: rgba(255, 255, 255, 0.02);
			}

			.list-active {
				padding-top: 20px;
				padding-bottom: 20px;
				padding-left: 40px;
				border-radius: 5px;
				background: rgba(255, 251, 209, 0.1);
				transition: 1s;
			}
		}
		.title {
			animation: slideToBottom 1s;
		}
		.list-active {
			animation: slideToBottom 1s;
		}
		.list-0 {
			animation: slideToBottom 1s;
		}
		.list-1 {
			animation: slideToBottom 1.4s;
		}
		.list-2 {
			animation: slideToBottom 1.8s;
		}
		.list-3 {
			animation: slideToBottom 2.2s;
		}
		.list-4 {
			animation: slideToBottom 2.6s;
		}
	}

	.col-right {
		margin-top: 20px;

		.list {
			padding: 20px 0;
			border-bottom: 1px solid rgba(255, 255, 255, .2);
			cursor: pointer;

			.flex {
				display: flex;
				justify-content: space-between;
				gap: 20px;
				align-items: center;

				.arrow-up {
					transform: rotate(-180deg);
					transition: 500ms
				}
				
				.arrow-down {
					transform: rotate(0deg);
					transition: 500ms
				}
			}

			.dropdown-content {
				position: relative;
				height: 0px;
				overflow: hidden;
				display: flex;
				flex-direction: column;
				transition: all 700ms ease;
			}
		}

		@for $i from 0 through 10 {
			.list-#{$i} {
				animation: slideToTop $i - .5 + s;
				animation-iteration-count: 1;
			}
		}
	}
}


@media screen and (max-width: 600px) {
	.section-4 .col-left .nav .list {
		padding-left: 10px;
		padding-right: 10px;
	}

	.section-4 .col-left .nav .list-active {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media screen and (min-width: 600px) {
	.section-4 {
		padding-left: 30px;
		padding-right: 30px;
	}	
}

@media screen and (min-width: 769px) {
	.section-4 {
		padding-left: 60px;
		padding-right: 60px;
	}	
}

@media screen and (max-width: 1479px) {
	.section-4 .col-left .nav .list {
		padding-left: 20px;
		padding-right: 20px;
	}

	.section-4 .col-left .nav .list-active {
		padding-left: 20px;
		padding-right: 20px;
	}
	
}

@media screen and (max-width: 390px) {
	.section-4 .col-left .nav .list {
		padding-left: 10px;
		padding-right: 10px;
	}
	
	.section-4 .col-left .nav .list-active {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media screen and (min-width: 1480px) {
	.section-4 {
		max-width: 1500px;

		.col-left .nav .list {
			padding-left: 20px;
			padding-right: 20px;
		}

		.col-left .nav .list-active {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
