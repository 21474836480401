.swiper-pagination {
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 18px;
  height: 18px;
  background: transparent;
  border: 1px solid #FFF;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 18px;
  height: 18px;
  transition: width 0.5s;
  border-radius: 99999px;
  background: #FFFF;
  border: 1px solid transparent;
}

.collection-swiper .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: transparent;
  border: 1px solid #FFF;
  opacity: 1;
}

.collection-swiper .swiper-pagination-bullet-active {
  width: 8px;
  height: 8px;
  transition: width 0.5s;
  border-radius: 99999px;
  background: #FFFF;
  border: 1px solid transparent;
}

@media only screen and (max-width: 600px) {
  .swiper-pagination-bullet {
		width: 12px;
		height: 12px;
	}
	.swiper-pagination-bullet-active {
		width: 12px;
		height: 12px;
	}
}