@import '../../../LandingPage/animation.scss';

.carousel {
	overflow: hidden;

	.inner {
		// white-space: nowrap;
		transition: transform 0.3s;
		position: relative;
	}

	.arrow-indicator {
		position: absolute;
		width: 100%;
		display: flex;
		gap: 20px;
		align-items: center;

		.left-arrow {
			position: absolute;
			left: 10px;
			bottom: 116px;
			transform: rotate(-90deg);
		}

		.right-arrow {
			position: absolute;
			right: 10px;
			bottom: 116px;
			transform: rotate(90deg);
		}
	}
}

.carousel-item {
	display: inline-flex;
	justify-content: center;
	color: #fff;
}

.carousel .indicators {
	display: none;
}

@keyframes dotWidthAnimation {
	from {
		width: 6px;
	}

	to {
		width: 20px;
	}
}

@keyframes dotReduceWidthAnimation {
	from {
		width: 20px;
	}

	to {
		width: 6px;
	}
}

@media screen and (min-width: 992px) {
	.carousel .indicators {
		position: absolute;
		bottom: 90px;
		left: 120px;
		z-index: 2;
		display: flex;
		gap: 10px;
		animation: slideToRight 1.8s;

		.dot {
			animation: dotReduceWidthAnimation 1s;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			background: #676555;
			cursor: pointer;
		}

		.dot-active {
			animation: dotWidthAnimation 1s;
			height: 6px;
			width: 20px;
			border-radius: 100px;
			background: #FFFBD1;
			cursor: pointer;
		}
	}
}

@media screen and (min-width: 1350px) {
	.carousel .indicators {
		left: 120px;
	}
}

@media screen and (min-width: 1480px) {
	.carousel .indicators {
		left: 10%;
	}
}